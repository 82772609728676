import React, { useEffect, useLayoutEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
// import Offers from "./pages/offers/Offers";

import {
  Login,
  LoginWithOTP,
  VerifyOTP,
  NameEmail,
  Address,
} from "./pages/onboarding";
import { Home, Search, ComboOfferList, ComboOffer } from "./pages/home";
import { Categories } from "./pages/categories";
import { Cart, Checkout } from "./pages/cart";
import { Orders, OrderDetails } from "./pages/orders";
import {
  Profile,
  ManageAddress,
  MyFavourites,
  AddEditAddress,
  FAQ,
} from "./pages/profile";

import { onMessageListener } from "./utils/fcm";
import {
  OnboardCarousel,
  NetworkStatus,
  MainSharedLayout,
  SharedLayout,
} from "./components/Main";

import { OnboardSharedLayout } from "./components/Onboard";

import {
  About,
  Contact,
  TermsAndConditions,
  PrivacyPolicy,
  CancellationAndRefund,
  ShippingAndDelivery,
} from "./pages/legal";

const KEY = process.env.REACT_APP_2FACTOR_API_KEY;
export const API = `https://2factor.in/API/V1/${KEY}/SMS`;

//* "proxy": "http://localhost:5000/", - To avoid CORS issues when requesting backend endpoints

function App() {
  const navigate = useNavigate();
  const location = useLocation().pathname;

  const user = useSelector((state) => state.user);
  useLayoutEffect(() => {
    const MainURL = "/main";
    const LegalURL = [
      "/about",
      "/contact",
      "/terms-and-conditions",
      "/privacy-policy",
      "/refund-and-cancellation",
      "/shipping-and-delivery",
    ];
    const LoginURL = ["/onboard/with-OTP", "/onboard/verify-otp"];
    const OnboardURL = ["/onboard/name-email", "/onboard/address"];
    const onboarded = localStorage.getItem("onboarded");
    const phoneNumber = localStorage.getItem("phoneNumber");
    const isLoggedIN = localStorage.getItem("isLoggedIN");

    const LoggedIN = isLoggedIN !== null && phoneNumber !== null;
    const userOnboarding = !LoggedIN && user === null;
    const userLoggedIN = LoggedIN && user !== null;

    // console.log(`OB: ${onboarded} | PN: ${phoneNumber} | iLI: ${isLoggedIN} | LN: ${LoggedIN} | UO: ${userOnboarding} | UL: ${userLoggedIN} | U: ${user}`)

    // ALLOW EVERYONE TO ACCESS LEGAL INFORMATION
    if (LegalURL.includes(location)) return;
    // FIRST TIME VISITOR
    if (onboarded === null) {
      // console.log("not onboarded");
      return navigate("/", { replace: true });
    }

    // SENDING & VERIFYING OTP
    if (location === LoginURL[1] && phoneNumber === null) {
      // console.log("phoneNumber is null");
      return navigate(LoginURL[0], { replace: true });
    } else if (LoginURL.includes(location) && userLoggedIN) {
      // console.log("user logged in");
      return navigate(MainURL, { replace: true });
    } else if (LoginURL.includes(location) && user === null) {
      // console.log("logging in...");
      return;
    }

    // ENTERING USERNAME & ADDRESS
    if (OnboardURL.includes(location) && userLoggedIN) {
      // console.log("onboard: logged in");
      return navigate(MainURL, { replace: true });
    } else if (OnboardURL.includes(location) && userOnboarding) {
      // console.log("onboard: not logged in");
      return navigate(LoginURL[0], { replace: true });
    }

    // RESTRICTING UNAUTHORIZED ACCESS - CHECK ALL ROUTES INSIDE MAIN
    if (
      (location.split("/").includes("main") ||
        location.split("/").includes("search") ||
        location.split("/").includes("checkout") ||
        location.split("/").includes("combo-offer")) &&
      !userLoggedIN
    ) {
      // console.log("LoggenIN and has Access");
      return navigate(LoginURL[0], { replace: true });
    }

    onMessageListener();
  }, [navigate, location, user]);

  const [theme, setTheme] = useState(null);

  useLayoutEffect(() => {
    // checks system theme
    if (window.matchMedia("(prefers-color-scheme:dark)").matches) {
      setTheme("dark");
      localStorage.setItem("theme", "dark");
    } else {
      setTheme("light");
      localStorage.setItem("theme", "light");
    }
    // Disable Right Click & Long Press
    document.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });
  }, []);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  return (
    <main className="relative w-screen h-screen flex flex-col bg-grey-light dark:bg-grey-dark font-inter text-grey-dark dark:text-grey-light select-none">
      <NetworkStatus />
      <Toaster toastOptions={{ duration: 3500 }} />
      <Routes>
        <Route path="/" element={<OnboardCarousel />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="refund-and-cancellation"
          element={<CancellationAndRefund />}
        />
        <Route path="shipping-and-delivery" element={<ShippingAndDelivery />} />

        {/* Register/Login */}
        <Route path="onboard" element={<OnboardSharedLayout />}>
          <Route index element={<Login />} />
          <Route path="with-OTP" element={<LoginWithOTP />} />
          <Route path="verify-OTP" element={<VerifyOTP />} />
          <Route path="name-email" element={<NameEmail />} />
          <Route path="address" element={<Address />} />
        </Route>
        {/* Main App */}
        <Route path="main" element={<MainSharedLayout />}>
          {/* // main/:outletName?/:categoryName? */}
          {/* HOME */}
          <Route index element={<Home />} />
          {/* OFFERS */}
          {/* <Route path="offers" element={<Offers />} /> */}
          {/* CATEGORIES */}
          <Route path="categories" element={<Categories />} />
          {/* CART */}
          <Route path="cart" element={<Cart />} />
          {/* ORDERS */}
          <Route path="orders" element={<SharedLayout />}>
            <Route index element={<Orders />} />
            <Route path=":orderID" element={<OrderDetails />} />
          </Route>
          {/* PROFILE */}
          <Route path="profile" element={<SharedLayout />}>
            <Route index element={<Profile />} />
            <Route path="favourites" element={<MyFavourites />} />
            <Route path="manage-address" element={<SharedLayout />}>
              <Route index element={<ManageAddress />} />
              <Route path="add-edit" element={<AddEditAddress />} />
            </Route>
            <Route path="faq" element={<FAQ />} />
          </Route>
        </Route>
        {/* Search */}
        <Route path="search" element={<Search />} />
        {/* Combo-Offer */}
        <Route
          path="combo-offer"
          element={
            <section className="px-4 overflow-scroll">
              <SharedLayout />
            </section>
          }
        >
          <Route path="all" element={<ComboOfferList />} />
          <Route path=":outletName/:offerId" element={<ComboOffer />} />
        </Route>
        {/* Checkout */}
        <Route
          path="checkout"
          element={
            <section className="px-4 overflow-scroll">
              <SharedLayout />
            </section>
          }
        >
          <Route index element={<Checkout />} />
        </Route>
      </Routes>
    </main>
  );
}

export default App;
